<template>
  <div class="your-age card">
    <div class="your-age__content-title-wrapper">
      <h5 class="your-age__content-title">Estimated Lung Age (ELA)</h5>
      <tooltip class="your-age__content-tip" v-if="tip">{{ tip }}</tooltip>
    </div>
    <p class="your-age__content-text" v-if="headline" v-html="headline"></p>
    <p class="your-age__content-text" v-else>No headline</p>
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip";

export default {
  components: { Tooltip },

  props: {
    headline: { type: String }
  },

  computed: {
    tip() {
      if (process.env.NODE_ENV === "test") {
        return null;
      }

      return (
        "Estimated Lung Age (ELA) is the average age of a healthy weight, non-smoker in your demographic group" +
        "(sex, height, and ethnicity) with your lung function metrics."
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.your-age {
  padding: 16px 26px;
  margin-bottom: 32px;

  &__content-title-wrapper {
    display: flex;
    align-items: center;
  }

  &__content-title {
    font-weight: $weight-bold;
    font-size: $size-sm;
    color: $color-primary;
  }

  &__content-tip {
    margin: 0 0.5rem 0.5rem;
  }

  &__content-text {
    font-size: 16px;
    line-height: 26px;
    color: $color-dark-light;
  }
}
</style>
