<template>
  <profile-layout>
    <error v-if="error" />

    <div class="dashboard" :class="{ dashboard_completed: sessions > 1 }" v-if="isLoaded">
      <template v-if="sessions < 2">
        <h1 class="profile__title">Dashboard</h1>
        <!-- <h2 v-if"isCareTaker"> Caretaker View </h2> -->
        <view-report v-if="sessions === 1" :report-data="reportData" />

        <not-enough-records />
      </template>

      <template v-else>
        <div class="profile__header">
          <tabs :tabs="tabs" v-model="activeTab" @click="tabClickHandler" />
        </div>

        <disclaimer-text />

        <h3 class="dashboard__title">
          <span>Based on {{ sessions }} {{ sessionLabel }}</span>
          <span>from {{ dates.from }} to {{ dates.to }}</span>
        </h3>

        <template v-if="isElaTabActive">
          <h4 class="dashboard__your-age-title">Estimated Lung Age (ELA)</h4>
          <your-age-chart
            label="ELA vs Actual Age"
            :your-ela-trajectory="dashboardData.your_ela_trajectory"
            :your-actual-age-trajectory="dashboardData.your_actual_age_trajectory"
            :chart-labels="dashboardData.labels"
            :polygons="dashboardData.polygons"
          />
          <your-age :headline="dashboardData.your_ela_text.box1" />
        </template>

        <template v-if="isMetricsTabActive">
          <charts
            v-for="type in lungReportTypes"
            :key="type"
            :chart-data="dashboardData[type]"
            :chart-labels="dashboardData.labels"
            :ref="type"
            :label="type"
          />
        </template>
      </template>
    </div>
  </profile-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProfileLayout from "@/components/profile/profileLayout";
import Tabs from "@/components/profile/profileTabs";
import DisclaimerText from "./components/disclaimerText";
import NotEnoughRecords from "./components/notEnoutgh";
import ViewReport from "./components/viewReport";
import YourAgeChart from "./components/yourAgeChart";
import YourAge from "./components/yourAge";
import Charts from "./components/chart";
import Error from "@/components/error";
import { dateFormatted } from "@/utils/date";
import { scrolltop } from "@/modules/utils";
import logVisiting from "@/mixins/logVisiting";

import {
  STORE_GET_DASHBOARD_DATA,
  POPUP_NOTIFICATION,
  MSG_EXPIRED_SESSION,
  LUNG_REPORT_TYPES,
  LUNG_REPORT_TABS
} from "@/constants";

export default {
  name: "dashboardPage",

  components: {
    ProfileLayout,
    NotEnoughRecords,
    ViewReport,
    Tabs,
    Charts,
    DisclaimerText,
    Error,
    YourAgeChart,
    YourAge
  },

  mixins: [logVisiting],

  inject: {
    showModal: { default: () => {} }
  },

  data: () => ({
    activeTab: Object.keys(LUNG_REPORT_TABS)[0],
    tabs: LUNG_REPORT_TABS,
    lungReportTabs: Object.keys(LUNG_REPORT_TABS),
    lungReportTypes: Object.keys(LUNG_REPORT_TYPES),
    error: false
  }),

  computed: {
    ...mapState(["dashboardData"]),
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),

    isLoaded() {
      return Boolean(this.dashboardData);
    },

    dates() {
      const { from, to } = this.dashboardData;
      return {
        from: dateFormatted(from),
        to: dateFormatted(to)
      };
    },

    reportData() {
      return {
        date: this.dashboardData?.to,
        id: this.dashboardData?.last_session_id
      };
    },

    sessions() {
      return this.dashboardData?.labels?.length || 0;
    },

    sessionLabel() {
      return this.sessions > 1 ? "sessions" : "session";
    },

    isElaTabActive() {
      return this.activeTab === this.lungReportTabs[1];
    },

    isMetricsTabActive() {
      return this.activeTab === this.lungReportTabs[0];
    }
  },

  mounted() {
    this.getDashboardData();
    this.checkExpiration();
  },

  methods: {
    ...mapActions([STORE_GET_DASHBOARD_DATA]),

    tabClickHandler() {
      scrolltop(0);
    },

    getDashboardData() {
      this[STORE_GET_DASHBOARD_DATA]().then(() => {console.log("got dashboard data");
        console.log(this.dashboardData)}).catch(this.errorHandler);
    },

    errorHandler() {
      this.error = true;
    },

    checkExpiration() {
      if (!this.$route.query?.expired) return;

      this.$router.replace({ name: this.$route.name, query: {} });

      this.showModal(POPUP_NOTIFICATION, { title: MSG_EXPIRED_SESSION });
    }
  }
};
</script>
