<template>
  <div class="not-enough-records">
    <card class="not-enough-records__card">
      <div class="not-enough-records__card-inner">
        <div class="not-enough-records__text">
          <p>
            We generate a Lung Performance Report™ for each sample you submit.
            See all your Lung Performance Reports™ in your Session History.
          </p>
          <p>
            Once you
            <a href="#" @click.prevent="clickHandler"> record</a> samples on two
            different days, this Dashboard will show charts and commentary
            describing changes in your lung function over time.
          </p>
          <p>
            Daily, weekly, monthly, or annually? Record voice samples as
            frequently as you like and especially when you detect a change in
            your lungs. For those with chronic lung diseases or concern about
            respiratory infections, we encourage more frequent use (e.g., daily
            or weekly).
          </p>
        </div>
        <img
          src="@/assets/images/no-sessions.svg"
          alt="no sessions"
          width="171"
          height="159"
          class="not-enough-records__image"
        />
      </div>
      <div class="not-enough-records__footer">
        <button v-if="!isCareTaker" class="button button_secondary button_md" @click="clickHandler">
          <span>Record sample</span>
          <span class="icon icon__arrow icon__arrow_right icon__arrow_angle" />
        </button>
      </div>
    </card>
  </div>
</template>

<script>
import Card from "@/components/card";
import { ROUTE_PROFILE_RECORD } from "@/constants";
import { mapState } from "vuex";

export default {
  components: { Card },

  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
  },
  methods: {
    clickHandler() {
      if(!this.isCareTaker)
        this.$router.push({ name: ROUTE_PROFILE_RECORD }, null, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";
@import "@/assets/styles/basic/mixins";

.not-enough-records {
  @include media-tablet {
    padding-top: 8rem;
  }

  &__card-inner {
    display: flex;
    align-items: center;

    @include media-tablet {
      flex-direction: column-reverse;
    }
  }

  &__footer {
    margin-top: 1.5rem;

    @include media-mobile {
      display: flex;
      justify-content: center;
    }
  }

  &__image {
    display: block;
    margin: 0 -7rem -3rem 3rem;
    filter: drop-shadow($shadow-small);
    transform: scale(1.3);

    @include media-tablet {
      margin: -8rem auto 1rem;
      transform: none;
    }
  }
}
</style>
