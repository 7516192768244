<script>
import { Line, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  extends: Line,

  mixins: [reactiveProp],

  props: {
    chartOptions: { type: Object, default: () => ({}) },
  },

  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>
