<template>
  <div class="dashboard__chart-wrapper">
    <h3 class="dashboard__label">{{ title }}</h3>
    <card v-if="comment">
      <template v-slot:title>{{ comment.title }}</template>
      <div>{{ comment.text }}</div>
    </card>
    <div class="dashboard__chart-list">
      <card v-for="(chart, index) in charts" :key="index" class="dashboard__chart card_sm">
        <line-chart :data-provider="chart" :labels="chartLabels" />
      </card>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/charts/line";
import Card from "@/components/card";
import { LUNG_REPORT_TYPES } from "@/constants";

export default {
  name: "dashboardList",

  components: { LineChart, Card },

  props: {
    chartData: { type: Object, default: () => ({}) },
    chartLabels: { type: Array, default: () => [] },
    label: { type: String, default: "" }
  },

  computed: {
    charts() {
      return this.chartData?.charts || [];
    },

    comment() {
      return this.chartData?.comment;
    },

    title() {
      return LUNG_REPORT_TYPES[this.label] || this.label;
    }
  }
};
</script>
