<template>
  <div class="dashboard-chart chart">
    <line-chart :chart-data="chartdata" :chart-options="chartOptions" class="chart__body" />
    <div class="dashboard-chart__legend-wrapper chart__legend-wrapper">
      <div class="dashboard-chart__legend chart__legend">
        <img
          class="dashboard-chart__bullet"
          src="@/assets/images/dashboard-chart/dashboard-chart-your-ela-line.png"
          alt
        />
        <div class="dashboard-chart__legend-title">Your ELA</div>
      </div>
      <div class="dashboard-chart__legend chart__legend">
        <img
          class="dashboard-chart__bullet"
          src="@/assets/images/dashboard-chart/dashboard-chart-your-age-line.png"
          alt
        />
        <div class="dashboard-chart__legend-title">Your Actual Age</div>
      </div>
    </div>
  </div>
</template>

<script>
import lineChart from "./_line";

export default {
  components: { lineChart },

  props: {
    dataProvider: { type: Array, required: true },
    labels: { type: Array, required: true }
  },

  computed: {
    dataValues() {
      const set = new Set();
      this.dataProvider.forEach(({ data }) => data.forEach(el => set.add(el)));
      return [...set];
    },

    yTicks() {
      const diff = 5;

      return {
        fontColor: "#C0C7CE",
        padding: 10,
        maxTicksLimit: 9,
        suggestedMin: Math.min(...this.dataValues) - diff,
        suggestedMax: Math.max(...this.dataValues) + diff
      };
    },

    chartdata() {
      return {
        //labels: this.labels,
        datasets: this.dataProvider.map(el => ({
          backgroundColor: "transparent",
          borderWidth: 3,
          lineTension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 0,
          pointHoverBorderWidth: 1,
          pointBorderWidth: 0,
          pointHoverBorderColor: "#fff",
          borderColor: "#002159",
          unit: "%",
          ...el
        }))
      };
    },

    chartOptions() {
      return {
        type: "scatter",
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 1,
        legend: { display: false },
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            top: 15,
            right: 15,
            bottom: 0,
            left: 0
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "#ddd",
                borderDash: [5],
                zeroLineBorderDash: [10],
                drawBorder: false
              }
            }
          ],
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                displayFormats: {
                  day: "M/D/YYYY"
                }
              },
              gridLines: {
                display: false
              },
              ticks: {
                maxTicksLimit: 4,
                fontColor: "#C0C7CE",
                maxRotation: 0,
                minRotation: 0
              }
            }
          ]
        },
        tooltips: {
          enabled: false
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.dashboard-chart {
  &__legend-wrapper {
    margin-top: 20px;
    margin-bottom: 0;
    padding-top: 18px;
    padding-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    border-top: 1px solid #e0e0e0;
    border-bottom: none;
    color: $color-dark-light;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__legend {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 3px;
  }

  &__legend:nth-child(odd) {
    padding-right: 24px;
  }

  &__bullet {
    padding-right: 16px;
  }

  &__legend-title {
    font-size: 14px;
    line-height: 14px;
    color: $color-dark-light;
  }
}
</style>
