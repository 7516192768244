<template>
  <card class="view-report">
    <div class="view-report__body">
      <div class="view-report__inner">
        <h3 class="view-report__title">View your Lung Performance Report™</h3>
        <div class="view-report__text">
          for the sample you recorded on {{ date }}
        </div>
      </div>
      <div class="view-report__footer">
        <button class="button button_blue button_md" @click="clickHandler">
          <span>view</span>
          <span class="icon icon__arrow icon__arrow_right icon__arrow_angle" />
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { dateFormatted } from "@/utils/date";
import { ROUTE_PROFILE_HISTORY_DETAILED } from "@/constants";

export default {
  components: { Card },

  props: {
    reportData: { type: Object, required: true },
  },

  computed: {
    date() {
      return dateFormatted(this.reportData.date);
    },
  },

  methods: {
    clickHandler() {
      this.$router.push(
        {
          name: ROUTE_PROFILE_HISTORY_DETAILED,
          params: { id: this.reportData.id },
        },
        null,
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";
@import "@/assets/styles/basic/mixins";

.view-report {
  &__body {
    display: flex;
    align-items: center;

    @include media-mobile {
      flex-direction: column;
    }
  }

  &__inner {
    @include media-mobile {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 0;
    color: $color-primary;
  }

  &__text {
    color: $color-primary;
    font-size: $size-sm;

    @include media-mobile {
      font-size: $size-xs;
    }
  }

  &__footer {
    margin-left: auto;

    @include media-mobile {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      margin-left: 0;
    }
  }
}
</style>
