<template>
  <div class="chart">
    <div class="chart__legend-wrapper">
      <div
        v-for="(legend, index) in legends"
        :key="index"
        class="chart__legend"
      >
        <div
          class="chart__bullet"
          v-if="legends.length > 1"
          :style="{ backgroundColor: legend.lineColor }"
        />
        <div class="chart__legend-title">{{ legend.title }}</div>
        <tooltip class="chart__legend-value">
          {{ legend.description }}
        </tooltip>
      </div>
    </div>
    <line-chart
      :chart-data="chartdata"
      :chart-options="chartOptions"
      class="chart__body"
    />
  </div>
</template>

<script>
import lineChart from "./_line";
import Tooltip from "../tooltip";
import { dateFormatted } from "@/utils/date";
import { isMobile } from "@/utils/system";

const getColor = (index) => {
  const colors = ["#4aa3f3", "#724BB7", "#c13584", "#13337d", "#0967d2"];
  return colors[index % colors.length];
};

export default {
  components: { lineChart, Tooltip },

  props: {
    dataProvider: { type: Array, required: true },
    labels: { type: Array, required: true },
  },

  computed: {
    isPercent() {
      return this.dataProvider[0].unit === "%";
    },

    dataValues() {
      const set = new Set();
      this.dataProvider.forEach(({ data }) =>
        data.forEach((el) => set.add(el))
      );
      return [...set];
    },

    yTicks() {
      const diff = this.isPercent
        ? 5
        : Math.abs(
            Math.max(...this.dataValues) - Math.min(...this.dataValues)
          ) / 2;

      return {
        fontColor: "#aaa",
        padding: 10,
        maxTicksLimit: 9,
        suggestedMin: Math.min(...this.dataValues) - diff,
        suggestedMax: Math.max(...this.dataValues) + diff,
      };
    },

    legends() {
      return this.dataProvider.map(({ label, description }, i) => ({
        title: label,
        description,
        lineColor: getColor(i),
      }));
    },

    chartdata() {
      return {
        labels: this.labels,
        datasets: this.dataProvider.map((el, i) => ({
          ...el,
          borderColor: getColor(i),
          borderWidth: 1,
          backgroundColor: "transparent",
          lineTension: 0.4,
          pointBackgroundColor: getColor(i),
          pointRadius: 4,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 3,
          pointBorderWidth: 3,
          pointBorderColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointHitRadius: isMobile ? 12 : 5,
        })),
      };
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 1,
        legend: { display: false },
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            top: 15,
            right: 15,
            bottom: 0,
            left: 0,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "#ddd",
                borderDash: [5],
                zeroLineBorderDash: [10],
                drawBorder: false,
              },
              ticks: this.yTicks,
            },
          ],
          xAxes: [
            {
              type: "time",
              distribution: this.labels.length > 2 ? "linear" : "series",
              time: {
                unit: "day",
              },
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#aaa",
                maxTicksLimit: 9,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: (item) => dateFormatted(item[0].label),
            label: (item) => dateFormatted(item.label, "time"),
            footer: ([item], { datasets }) =>
              `${item.value} ${datasets[item.datasetIndex].unit || ""}`,
          },
          displayColors: false,
          backgroundColor: "#444",
          xPadding: 10,
          yPadding: 10,
          titleFontSize: 14,
          footerMarginTop: 10,
          footerFontSize: 20,
          footerFontColor: "#4aa3f3",
          caretPadding: 10,
          caretSize: 7,
        },
      };
    },
  },
};
</script>
