<template>
  <div class="ela-chart__wrapper">
    <card class="ard_sm">
      <template v-slot:title>{{ title }}</template>
      <div class="ela-chart__chart-wrapper">
        <chart :data-provider="chart" :labels="chartLabels" />
      </div>
    </card>
  </div>
</template>

<script>
import Chart from "@/components/charts/yourAgeLine";
import Card from "@/components/card";

const RED = "rgba(248, 106, 106, 0.4)";
const GREEN = "rgba(101, 214, 173, 0.4)";

export default {
  components: { Chart, Card },

  props: {
    label: { type: String, default: "" },
    yourElaTrajectory: { type: Array, default: () => [] },
    yourActualAgeTrajectory: { type: Array, default: () => [] },
    chartLabels: { type: Array, default: () => [] },
    polygons: { type: Array, default: () => [] }
  },

  computed: {
    chart() {
      const yourElaTrajectory = this.yourElaTrajectory.map((v, i) => ({
        x: this.chartLabels[i],
        y: v
      }));

      const polygonDatasets = this.polygons.map((polygon, index) => {
        const { sign, points } = polygon;
        const color = sign ? RED : GREEN;
        let data = [];

        if (this.polygons.length === 1) {
          data = [
            ...points,
            {
              x: this.chartLabels[this.chartLabels.length - 1],
              y: this.yourActualAgeTrajectory[1]
            },
            { x: this.chartLabels[0], y: this.yourActualAgeTrajectory[0] },
            points[0]
          ];
        } else if (index === 0) {
          data = [
            ...points,
            { x: this.chartLabels[0], y: this.yourActualAgeTrajectory[0] },
            points[0]
          ];
        } else if (index === this.polygons.length - 1) {
          data = [
            ...points,
            {
              x: this.chartLabels[this.chartLabels.length - 1],
              y: this.yourActualAgeTrajectory[1]
            },
            points[0]
          ];
        } else {
          data = [...points, points[0]];
        }

        return {
          label: `Plygon ${index}`,
          data,
          backgroundColor: color,
          lineTension: 0,
          borderColor: "transparent"
        };
      });

      return [
        {
          label: "Your Actual Age",
          data: [
            { x: this.chartLabels[0], y: this.yourActualAgeTrajectory[0] },
            {
              x: this.chartLabels[this.chartLabels.length - 1],
              y: this.yourActualAgeTrajectory[1]
            }
          ]
        },
        {
          label: "Your ELA Age",
          data: yourElaTrajectory,
          lineTension: 0,
          borderDash: [7, 3]
        },
        ...polygonDatasets
      ];
    },

    title() {
      return this.label;
    }
  },

    mounted() {
        console.log("mounted your-age-chart component");
        console.log(this.chartLabels);
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";
.ela-chart__notion-text {
  font-size: 16px;
  line-height: 26px;
  color: $color-dark-light;
  margin-top: 0;

  & > a {
    font-size: 16px;
    line-height: 26px;
    color: $color-blue;
    text-transform: none;
  }
}

.ela-chart__chart-wrapper {
  position: relative;
}

.ela-chart__disability-zone-label {
  position: absolute;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $color-grey-dark;
  top: 220px;
  left: 50%;
  transform: translateX(-30%);
}
</style>
